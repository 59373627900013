import authBloc from "../../services/bloc";
import guard from '@/guard';
import { Vue } from 'vue-class-component';
import { ElLoading } from 'element-plus';
import { widget } from "@cems-eco/core/src/utils";

export abstract class BaseAuthSite extends Vue {
    authSiteBloc = authBloc;
    sites: any = [];
    healthcheck: any = {};
    username = "Admin";

    abstract siteHealthcheck(site: any);


    async loginSite(site: any) {
        const loader = ElLoading.service({ lock: true });
        try {
            if(this.statusHealthcheck(site) != "success") return;
            await this.authSiteBloc.site(site);
            guard.checkAuthentication();
            loader.close();
        } catch (error) {
            loader.close();
            widget.alertError(error);
        }
    }

    statusHealthcheck(site: any) {
        if(!(site.id in this.healthcheck)) return "loading";

        return this.healthcheck[site.id];
    }

    statusIcon(site) {
        const status = this.statusHealthcheck(site);
        switch(status) {
            case 'loading':
                return 'fas fa-spinner fa-spin';
            case 'success':
                return 'fas fa-check-circle text-success';
            case 'fail':
                return 'fas fa-times-circle text-danger';
        }
    }

    statusCard(site){
        const status = this.statusHealthcheck(site);
        switch(status) {
            case 'loading':
            case 'fail':
                return 'card card-fail';
            case 'success':
                return 'card card-success';
        }
    }
}

