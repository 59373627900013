import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid mt--6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_main_footer = _resolveComponent("main-footer")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_router_view, null, {
        default: _withCtx(({ Component }) => [
          _createVNode(_Transition, {
            name: "fade",
            mode: "out-in"
          }, {
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
            ]),
            _: 2
          }, 1024)
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_main_footer, { class: "is-web" })
  ]))
}