
import { BoxMaintenanceEditPage } from './edit.box';
import { CloudMaintenanceEditPage } from './edit.cloud';
import { DemoMaintenanceEditPage } from './edit.demo';

import { platform } from '@cems-eco/core/src/utils';
import environment from "@/config";

export default class MaintenanceEdit extends platform.mixins(environment.nexus, {
    box: BoxMaintenanceEditPage,
    cloud: CloudMaintenanceEditPage,
    demo: DemoMaintenanceEditPage
}) { }
