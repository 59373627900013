import { BaseMonitorViewPage } from './view';
import { Options } from 'vue-class-component';
import components from "../../components/component";
import { ElLoading } from "element-plus";


@Options({
    components: components
})
export class BoxMonitorViewPage extends BaseMonitorViewPage {

    async mounted() {
        const loader = ElLoading.service({ lock: true });
        try {
            this.equipmentId = this.$route.params.id;
            const response: any = await this.monitorBloc.getEquipment(this.equipmentId);
            this.equipment = response.data;
            this.equipments.push(this.equipment);
            this.setTabs();
            loader.close();
        } catch (error) {
            loader.close();
            console.log(error);
        }
    }
}
