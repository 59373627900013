import { BaseAuthSite } from './site';
import guard from '@/guard';
import { IUserStorage } from '@/storage/model';
import storage from '@/storage';
import { flattenDeep } from 'lodash';
import { ElLoading } from "element-plus";
import { widget } from '@cems-eco/core/src/utils';

export class CloudAuthSitePage extends BaseAuthSite {
    async created() {
        await guard.checkAuthentication();

        const userStorage: IUserStorage = await storage.get<IUserStorage>(storage.USER);
        this.username = userStorage.name;
    }

    async mounted() {
        const loader = ElLoading.service({ lock: true });
        try {
            this.sites = await this.authSiteBloc.getSite();
            loader.close();

            let promises = this.sites.map((client: any) => {
                return client.children;
            });
            promises = flattenDeep(promises).map((site: any) => {
                return this.siteHealthcheck(site);
            });
            await Promise.all(promises);
        } catch (error) {
            loader.close();
            widget.alertError(error);
        }

    }

    async siteHealthcheck(site: any) {

        try {
            if (!('hash' in site)) {
                this.healthcheck[site.id] = "fail";
            } else {
                await this.authSiteBloc.healthcheck(site.hash);
                this.healthcheck[site.id] = "success";
            }
        } catch (e) {
            this.healthcheck[site.id] = "fail";
        }
    }
}

