import { IRemotePage } from ".";
import { ElLoading } from "element-plus";

export class DemoIRemotePage extends IRemotePage {

    options = {}

    async mounted() {
        const loader = ElLoading.service({ lock: true });
        await this.builDataTable();
        loader.close();
    }

    async builDataTable() {
        this.options = {

            mobileView: {
                header: {
                    title: function (data: any) {
                        return data[0]
                    },
                }
            },
            columns: [
                { mobileView: false },
                { mobileView: true },
                { mobileView: true },
                { mobileView: true },
            ],
            data: [
                [
                    "GAS ANALYZER (6500160)",
                    "01/03/2023 09:23:29",
                    "01/03/2023 09:23:29",
                    "Success"
                ],
                [
                    "GAS ANALYZER (6500160)",
                    "01/03/2023 09:23:29",
                    "01/03/2023 09:23:29",
                    "Success"
                ],
                [
                    "GAS ANALYZER (6500160)",
                    "01/03/2023 09:23:29",
                    "01/03/2023 09:23:29",
                    "Success"
                ],
                [
                    "GAS ANALYZER (6500160)",
                    "01/03/2023 09:23:29",
                    "01/03/2023 09:23:29",
                    "Success"
                ],
                [
                    "GAS ANALYZER (6500160)",
                    "01/03/2023 09:23:29",
                    "01/03/2023 09:23:29",
                    "Success"
                ],
                [
                    "GAS ANALYZER (6500160)",
                    "01/03/2023 09:23:29",
                    "01/03/2023 09:23:29",
                    "Success"
                ],
                [
                    "GAS ANALYZER (6500160)",
                    "01/03/2023 09:23:29",
                    "01/03/2023 09:23:29",
                    "Success"
                ],
                [
                    "GAS ANALYZER (6500160)",
                    "01/03/2023 09:23:29",
                    "01/03/2023 09:23:29",
                    "Success"
                ],
                [
                    "GAS ANALYZER (6500160)",
                    "01/03/2023 09:23:29",
                    "01/03/2023 09:23:29",
                    "Success"
                ],
                [
                    "GAS ANALYZER (6500160)",
                    "01/03/2023 09:23:29",
                    "01/03/2023 09:23:29",
                    "Success"
                ],
                [
                    "GAS ANALYZER (6500160)",
                    "01/03/2023 09:23:29",
                    "01/03/2023 09:23:29",
                    "Success"
                ],
                [
                    "GAS ANALYZER (6500160)",
                    "01/03/2023 09:23:29",
                    "01/03/2023 09:23:29",
                    "Success"
                ],
            ],
        };
    }

}

