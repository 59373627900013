import iremoteBloc from "../../services/bloc";
import { ElLoading } from "element-plus";
import { Vue } from "vue-class-component";

export abstract class IRemotePage extends Vue {

    iremoteBloc = iremoteBloc;
    options = {};
    headers: any = {
        responseType: "json",
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    };

    async mounted() {
        const loader = ElLoading.service({ lock: true });
        this.updateHeaders();
        await this.builDataTable();
        loader.close();
    }

    updateHeaders() {};

    async builDataTable() {
        const url = await this.iremoteBloc.getIRemoteUrl();
        this.options = {
            mobileView: {
                header: {
                    title: function (data: any) {
                        return data.stack_id;
                    },
                }
            },
            serverSide: true,
            ajax: {
                url: url,
                method: 'GET',
                dataType: 'json',
                dataFilter: (data) => {
                    const parsedData = JSON.parse(data);
                    return JSON.stringify(parsedData.data);
                },
                headers: this.headers,
            },
            columns: [
                { data: 'stack_id',mobileView: false },
                { data: 'read_date_time', mobileView: true },
                { data: 'date_time', mobileView: true },
                { data: 'log_message', mobileView: true },
            ]
        };
    }

}

