import { MaintenanceEditPage } from './edit';
import { widget } from '@cems-eco/core/src/utils';
import { PartSupplied } from '../../services';

export class DemoMaintenanceEditPage extends MaintenanceEditPage {

    mounted() {
        this.maintenance = {
            id: "1",
            _method: "",
            name: "6",
            client_id: "client",
            site_id: "site",
            equipment_id: "equipment",
            user_id: "user",
            diagnosed: "System outdated",
            remarks: "Update software",
            progress: "0",
            part_supplied: [
                {
                    part_number: "PART/0001",
                    description: "Part Description",
                    quantity: 1,
                    remarks: "Part Remarks",
                }
            ],
            start_date: "2021-09-10",
            end_date: "2021-09-10",
            start_time: "22:00",
            end_time: "23:16",
            additional: "",
            serial_number: "SR/2021/0003",
            tag_number: "-",
            next_service_reminder: "2021-10-10",
        };
    }

    async save() {
        try {
            await widget.alertSuccess('Good Job!', 'You have successfully edit this Maintenance');
            this.$router.push(`/maintenance/id`)
        } catch (error) {
            console.log(error);
        }
    }
}