import { ManagementDocumentPage } from './document';
import { widget } from '@cems-eco/core/src/utils';

export class DemoManagementDocumentPage extends ManagementDocumentPage {

    mounted() {
        this.document = {
            file: '',
            original_name: '',
            user_id: 'user',
            management_id: '',
            description: '',
        };
        this.document.management_id = this.$route.params.id.toString();
    }

    async save() {
        try {
            await widget.alertSuccess('Good Job!', 'You have successfully upload file');
            this.$router.push(`/management`)
        } catch (error) {
            console.log(error);
        }
    }
}