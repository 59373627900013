

import { CloudAuthSitePage } from './site.cloud';
import { DemoAuthSitePage } from './site.demo';

import { platform } from '@cems-eco/core/src/utils';
import environment from "@/config";

export default class Site extends platform.mixins(environment.nexus, {
  box: CloudAuthSitePage,
  cloud: CloudAuthSitePage,
  demo: DemoAuthSitePage
}){}
