
import { platform } from "@cems-eco/core/src/utils";
import environment from "@/config";

import BoxReportPage from "./index.box";
import CloudReportPage from "./index.cloud";
import DemoReportPage from "./index.demo";


export default class Web extends platform.mixins(environment.nexus, {
    box: BoxReportPage,
    cloud: CloudReportPage,
    demo: DemoReportPage,
}) { }
