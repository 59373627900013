import { BaseIRemoteApi } from './api.base';
import { apiNet as api } from '@cems-eco/core/src/services/api';

export class CloudIRemoteApi extends BaseIRemoteApi {

    async all(): Promise<any> {
        return await api.get<any>(this.BASE_URL, true);
    }

    async iremoteUrl(): Promise<any> {
        return await api.getUrl(this.BASE_URL)
    }
}