import { IRemote } from "./index"
import iremoteApi from "./api"
import environment from "@/config"

export abstract class BaseIRemoteBloc {
    module = 'iremote';
    api = iremoteApi;
    base_url = environment.API_URL + '/iremote';

    async getIRemote() : Promise<[IRemote]> {
        let response = await this.api.all()
        return response.data;
    }

    async getIRemoteUrl() : Promise<string> {
        return await this.api.iremoteUrl();
    }

}

