
import { BoxMonitorViewPage } from './view.box';
import { CloudMonitorViewPage } from './view.cloud';
import { DemoMonitorViewPage } from './view.demo';

import { platform } from '@cems-eco/core/src/utils';
import environment from "@/config";

export default class WebMonitorView extends platform.mixins(environment.nexus, {
    box: BoxMonitorViewPage,
    cloud: CloudMonitorViewPage,
    demo: DemoMonitorViewPage
}) {
    searching = false;

    onSearchToggle() {
        this.searching = !this.searching;
    }
}
