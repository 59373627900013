import { ManagementDocumentPage } from './document';
import { widget } from '@cems-eco/core/src/utils';
import storage from '@/storage';
import { ElLoading } from "element-plus";
import { IUserStorage } from '@/storage/model';

export class CloudManagementDocumentPage extends ManagementDocumentPage {

    async mounted() {
        const loader = ElLoading.service({ lock: true });

        const userStorage: IUserStorage = await storage.get<IUserStorage>(storage.USER);

        this.document.management_id = this.$route.params.id.toString();
        this.document.user_id = userStorage.id;

        loader.close();
    }

    async save() {
        const loader = ElLoading.service({ lock: true });
        try {
            await this.documentBloc.uploadDocument(this.document);
            loader.close();
            await widget.alertSuccess('Good Job!', 'You have successfully create this document');
            this.$router.push(`/management`);
        } catch (error) {
            loader.close();
            widget.alertError(error);
        }
    }

}