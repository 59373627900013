export interface IDocument {
    readonly id: any;
    user_id: string
    management_id: string
    original_file_name: string
    file_name: string
    path: string
}

export class Document {
    public id!: any;
    public _method!: string;
	public user_id!: string;
	public management_id!: string;
	public original_file_name!: string;
	public file_name!: string;
	public path!: string;
}

export class UploadRequest { 
    file: string;
    original_name: string;
    user_id: string;
    management_id: string;
    description: string;
}