import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveDynamicComponent as _resolveDynamicComponent, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-75e6069f"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "row"
}
const _hoisted_2 = { class: "col-md-12" }
const _hoisted_3 = { class: "col-md-12" }
const _hoisted_4 = { class: "card pt-3" }
const _hoisted_5 = {
  role: "tablist",
  class: "nav nav-pills nav-fill px-4 mt-2"
}
const _hoisted_6 = ["id", "href", "aria-controls", "aria-selected", "onClick"]
const _hoisted_7 = { class: "d-sm-block pl-2 text-uppercase" }
const _hoisted_8 = {
  key: 0,
  class: "tab-pane active",
  role: "tabpanel"
}
const _hoisted_9 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CemsReading = _resolveComponent("CemsReading")!

  return (!_ctx.isLoaded)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(), _createBlock(_component_CemsReading, {
            key: _ctx.equipment.id,
            type: "advance",
            equipment: _ctx.equipment
          }, null, 8, ["equipment"]))
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_Transition, {
              name: "custom-classes-transition",
              "enter-active-class": "animate__animated animate__fadeIn animate__faster",
              "leave-active-class": "animate__animated animate__fadeOut animate__faster",
              mode: "out-in"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, [
                  _createElementVNode("ul", _hoisted_5, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs.list, (item, index) => {
                      return (_openBlock(), _createElementBlock("li", {
                        key: index,
                        class: "nav-item mb-sm-3"
                      }, [
                        _createElementVNode("a", {
                          class: _normalizeClass(["nav-link d-flex justify-content-center align-items-center shadow-none", { active: item.active }]),
                          id: item.id + '-tab',
                          "data-toggle": "tab",
                          href: '#' + item.id,
                          "aria-controls": item.id,
                          role: "tab",
                          "aria-selected": item.active,
                          onClick: ($event: any) => (_ctx.onTabChanged(index))
                        }, [
                          _createElementVNode("span", _hoisted_7, _toDisplayString(item.name), 1)
                        ], 10, _hoisted_6)
                      ]))
                    }), 128))
                  ]),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs.list, (item, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: index,
                      class: "tab-content"
                    }, [
                      _createVNode(_Transition, {
                        name: "fade",
                        mode: "out-in"
                      }, {
                        default: _withCtx(() => [
                          (index === _ctx.activeTabIndex)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                                (_openBlock(), _createBlock(_resolveDynamicComponent(item.component), {
                                  equipment: _ctx.equipment,
                                  equipmentId: _ctx.equipmentId,
                                  searchMood: false
                                }, null, 8, ["equipment", "equipmentId"]))
                              ]))
                            : (_openBlock(), _createElementBlock("div", _hoisted_9))
                        ]),
                        _: 2
                      }, 1024)
                    ]))
                  }), 128))
                ])
              ]),
              _: 1
            })
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}