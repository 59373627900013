import { BaseMonitorViewPage } from './view';
import { Options } from 'vue-class-component';
import components from "../../components/component";
import { ElLoading } from "element-plus";

@Options({
  components: components
})
export class DemoMonitorViewPage extends BaseMonitorViewPage {

  async created() {
    const loader = ElLoading.service({ lock: true });
    this.equipmentId = 1;
    this.equipment = {
      name: "602 TRANSMITTER (S/N:56146)",
      manufacturer: "N/A",
      model: "N/A",
      date_manufactured: new Date,
      serial_number: "56146",
      measurements: ["DUST"],
      configurations: ["DUST 0 - 30 mg/m3"]
    };
    this.equipments.push(this.equipment);
    this.setTabs();
    loader.close();
  }
}
