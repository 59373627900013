import documentBloc from "../../services/document/bloc";
import { Vue, Options } from 'vue-class-component';
import component from "../../components/component";
import { UploadRequest } from "../../services/document";


@Options({
    components: component,
})
export class ManagementDocumentPage extends Vue {
    documentBloc = documentBloc;
    document = new UploadRequest;
}

