import { MaintenanceEditPage } from './edit';
import { widget } from '@cems-eco/core/src/utils';
import storage from '@/storage';
import { ElLoading } from "element-plus";

export class BoxMaintenanceEditPage extends MaintenanceEditPage {

    async mounted() {
        const loader = ElLoading.service({ lock: true });

        this.maintenance.id = this.$route.params.id
        this.maintenance = await this.maintenanceBloc.getMaintenance(this.maintenance.id);
        this.updateDatetimeInput();

        loader.close();
    }

    async save() {
        const loader = ElLoading.service({ lock: true });
        try {
            this.maintenance = await this.maintenanceBloc.updateRelation(this.maintenance);
            this.maintenanceId = this.maintenance.id;
            loader.close();
            await widget.alertSuccess('Good Job!', 'You have successfully edit this Maintenance');
            this.$router.push(`/maintenance/${this.maintenanceId}`);
        } catch (error) {
            loader.close();
            widget.alertError(error);
        }
    }

}