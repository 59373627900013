import storage from "@/storage";
import { IRemotePage } from ".";
import { ElLoading } from "element-plus";

export class CloudIRemotePage extends IRemotePage {

    updateHeaders() {
        let auth = storage.getSync<any>(storage.SITE_AUTH);
        if (auth) {
            this.headers.Authorization = `Bearer ${auth["access_token"]}`;
        }
    }
}

