
import { BoxManagementDocumentPage } from './document.box';
import { CloudManagementDocumentPage } from './document.cloud';
import { DemoManagementDocumentPage } from './document.demo';

import { platform } from '@cems-eco/core/src/utils';
import environment from "@/config";

export default class ManagementEdit extends platform.mixins(environment.nexus, {
    box: BoxManagementDocumentPage,
    cloud: CloudManagementDocumentPage,
    demo: DemoManagementDocumentPage
}) { }
