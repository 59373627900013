import { BaseAuthSite } from './site';
import router from "@/router";
import { ElLoading } from 'element-plus';

export class DemoAuthSitePage extends BaseAuthSite {

    // Override parent function
    async created() {
        // Leave empty
    }

    async mounted() {
        this.sites = this.getSite();
        for (const key in this.sites) {
            const client: any = this.sites[key];
            for (const siteKey in client.children) {
                const site = client.children[siteKey];
                try {
                    this.healthcheck[site.id] = "success";
                } catch (e) {
                    this.healthcheck[site.id] = "fail";
                }
            }
        }
    }
    getSite(): any {
        return [
            {
                "id": "1469a6c2-3b2c-4b53-a91e-288fa765f163",
                "text": "TEST",
                "children": [
                    {
                        "id": "2c9b7246-46af-4bad-84e2-a9346232eaf8",
                        "text": "TEST",
                    }
                ]
            }
        ]
    }

    async loginSite(site: any) {
        const loader = ElLoading.service({ lock: true });
        setTimeout(() => {
            router.replace('/monitor');
            loader.close();
        }, 1000);
    }

    async siteHealthcheck(site: any) {
    }

}

