import { BaseMonitorViewPage } from './view';
import { Options } from 'vue-class-component';
import components from "../../components/component";
import { ElLoading } from "element-plus";


@Options({
    components: components
})
export class CloudMonitorViewPage extends BaseMonitorViewPage {

    async created() {
        const loader = ElLoading.service({ lock: true });
        this.equipmentId = this.$route.params.id;
        const response: any = await this.monitorBloc.getEquipment(this.equipmentId);
        this.equipment = response.data;
        this.equipments.push(this.equipment);
        this.setTabs();
        loader.close();
    }
}
