import { api } from '@cems-eco/core/src/services/api';

export abstract class BaseIRemoteApi {
    BASE_URL: string;

    
    constructor() {
        this.BASE_URL = "/iremote";
    }

    async all(): Promise<any> {
        return await api.get<any>(this.BASE_URL, true);
    }

    async iremoteUrl(): Promise<any> {
        return await api.getUrl(this.BASE_URL)
    }
;

}
