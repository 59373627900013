
import { BoxIRemotePage } from './index.box';
import { CloudIRemotePage } from './index.cloud';
import { DemoIRemotePage } from './index.demo';

import { platform } from '@cems-eco/core/src/utils';
import environment from "@/config";

export default class MaintenanceIndex extends platform.mixins(environment.nexus, {
  box: BoxIRemotePage,
  cloud: CloudIRemotePage,
  demo: DemoIRemotePage
}){}
