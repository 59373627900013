import monitorBloc from "../../services/bloc";
import { Vue } from 'vue-class-component';
import { Tab } from "@cems-eco/core/src/models";
import { isEmpty } from "lodash"; // use in template

export class BaseMonitorViewPage extends Vue {
    monitorBloc = monitorBloc
    equipment: any = {};
    equipments: any = [];
    equipmentId: any;
    tabs: Tab
    activeTabIndex: number = 0;

    
    public get isLoaded() : boolean {
        return isEmpty(this.equipment);
    }
    

    setTabs() {
        this.tabs = new Tab
        this.tabs.list = [
            {
                id: 'alarm',
                name: 'Alarm',
                icon: 'fa fa-bell',
                active: true,
                component: 'AlarmComponent',
            },
            {
                id: 'system',
                name: 'System',
                icon: 'fa fa-info-circle',
                active: false,
                component: 'SystemComponent',
                // props: {
                //     equipmentId: this.equipment.id,
                //     equipment: this.equipment
                // }
            },
        ];
    }

    onTabChanged(index: number) {
        this.activeTabIndex = index;
    }
    
}
